.ticket-create-select-wrapper .st-select-form{
  margin: 0
}

.ep-client-data.cl-field .MuiInput-root{
  background-color: $field-color;
  border: 1px solid $border-field-color;
  border-radius: 4px;
}

.ep-client-data.cl-field .MuiInput-underline:before{
  border: none !important;
}

.ep-client-data.cl-field .MuiInput-underline:after{
  border: none !important;
}

.ep-client-data.cl-field .MuiInput-underline:focus{
  border: none !important;
}
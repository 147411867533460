.mtable.timeslot-table .MuiTableCell-root{
  border: none;
  font-size: 14px;
  padding: 5px 4px;
  color: $text-light-title-color;
}


.mtable.timeslot-table .MuiTableHead-root .MuiTableCell-root{
  border: none;
  font-size: 14px;
  padding: 5px 4px;
  color: $text-light-title-color;
  font-weight: normal;
}

.mtable.timeslot-table .MuiTableHead-root .MuiTableCell-root.workday-slot-title{
  font-weight: normal;
  font-size: 18px;
  color: $text-color;
}

.mtable.timeslot-table .MuiTableHead-root .MuiTableCell-root.capasity-cell-ts-title{
  width: 115px;
  padding-left: 20px;
}

.mtable.timeslot-table .MuiTableHead-root .MuiTableCell-root.hour-cell-ts-title{
  width: 300px;
}

.mtable.timeslot-table .MuiTableHead-root .MuiTableCell-root.space-title{
  width: 15px;
}

.mtable.timeslot-table .MuiTableCell-root .MuiInputBase-input{
  font-size: 14px;
  padding: 11px 10px;
  color: $text-color;
  background-color: $field-color;
}

.mtable.timeslot-table .MuiTableCell-root.till-cell-ts{
  padding-left: 10px;
  padding-right: 0;
}

.mtable.timeslot-table .MuiTableCell-root.capasity-cell-ts{
  width: 30px;
  padding-left: 15px;
}

.mtable.timeslot-table .MuiTableCell-root.capasity-cell-ts .MuiInputBase-root{
  width: 70px;
}

.mtable.timeslot-table .MuiTableCell-root.min-cell-ts .MuiInputBase-root{
  width: 68px;
  padding: 0;
}

.mtable.timeslot-table .MuiTableCell-root.hour-cell-ts .MuiInputBase-root{
  width: 50px;
  padding: 0;
}

.timeslot-container{
  /* min-width: 986px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.time-slot-add-button.MuiButtonBase-root{
  text-transform: none;
  color: $link-color;
  font-size: 14px;
  font-weight: normal;
  padding-left: 5px;
  padding-right: 80px;
}

.workday-ts-wrapper{
  width: 430px;
  margin: 10px 30px;
}

.del-cell-ts img{
  cursor: pointer;
  margin-left: 7px;
}

.timeslot-dialog .MuiPaper-root.MuiDialog-paper.MuiPaper-rounded{
  max-width: 980px;
}

.timeslot-dialog .MuiDialogContent-root{
  padding: 10px 0;

}

.timeslot-dialog .MuiDialogContent-root .MuiContainer-root{
  padding: 0;
}

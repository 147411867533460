div.menu-icon-wrapper {
    min-width: 40px;
    padding-left: 2px;
    color: inherit;
}

div.menu-icon-wrapper img {
    width: 20px;
}

hr.menu-divider {
    background-color: #535353;
}

li.active {
    background: #252525 url("data:image/svg+xml,%3Csvg width='7' height='16' viewBox='0 0 7 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.748845 8.72058C0.340012 8.32718 0.340012 7.67282 0.748845 7.27942L7.30662 0.969196C7.94193 0.357875 9 0.808113 9 1.68977L9 14.3102C9 15.1919 7.94193 15.6421 7.30663 15.0308L0.748845 8.72058Z' fill='white'/%3E%3C/svg%3E") center no-repeat;
    background-position-x: right;
}

li.active span {
    color: #fff;
}

.MuiDivider-flexItem.dash-pie-divider{
    margin: 10px 20px 10px 10px;
}

.dash-color-marker{
    width: 11px;
    height: 11px;
    border-radius: 100%;
}

.dash-color-marker-col{
    width: 20px;
}
.dash-two-in-row {
    margin-top: 40px;
}
.dash-two-in-row > .MuiCard-root{
    flex-grow: 1;
}
.dash-two-in-row > .MuiCard-root:first-child{
    margin-right: 18px;
}
.menu-icon{
    margin-left: 3px;
}

.menu-drawer {
    margin-right: 24px;
    background: $menu-color;
}

.menu-item {
    color: inherit;
}
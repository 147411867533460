.st-select-field.st-cities-field{
  margin: 22px 0;
  font-size: 14px;
  height: 40px;
  background-color: $field-color;
  border-radius: 4px;
  border: 1px solid $border-field-color;
  padding-left: 0 !important;
}

.st-select-field.st-cities-field .MuiInput-input {
  padding: 18.5px 14px !important;
}

.st-select-field.st-cities-field .MuiInput-underline:before{
  border: none !important;
}

.st-select-field.st-cities-field .MuiInput-underline:after{
  border: none !important;
}

.st-select-field.st-cities-field .MuiInput-underline:focus{
  border: none !important;
}

.MuiFormControl-root label.st-select-lable{
  transform: translate(0px, 0) scale(1);
  font-size: 12px;
}

.MuiPaper-elevation8 {
  box-shadow: none !important;
  background-color: $field-color !important;
  border: 1px solid $border-field-color;
}

.MuiPickersCalendarHeader-iconButton {
  background-color: transparent !important;
}

.st-select-field.st-select-form{
  margin: 5px !important;
  width: 270px;
}

.st-select-field.st-select-form .MuiInput-underline:before{
  border: none !important;
}

.st-select-field.st-select-form .MuiInput-underline:after{
  border: none !important;
}

.st-select-field.st-select-form .MuiInput-underline:focus{
  border: none !important;
}

.st-select-field.st-cities-field .MuiOutlinedInput-input{
  font-size: 14px;
}

.MuiFormLabel-root.Mui-focused{
  color: rgba(0, 0, 0, 0.54) !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid $border-field-color !important;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.st-data-picker .MuiInputBase-root{
  border-radius: 4px;
  border: 1px solid $border-field-color;
  padding: 3px 5px;
  margin: 16px 4px;
  background-color: $field-color;
}

.st-data-picker .MuiInputBase-root::before{
  border: none;
}
.st-data-picker .MuiInputBase-root:hover::before{
  border-bottom: none;
}

.st-data-picker .MuiFormLabel-root{
  transform: translate(4px, -4px) scale(1);
  font-size: 13px;
}

.st-xlsx-button.st-icon{
  height: 40px;
  margin-top: 35px;
}
.MuiButtonBase-root[disabled] img{
  filter: grayscale(90%) blur(2px) brightness(200%);
}

.MuiListItem-root.Mui-selected {
  background-color: transparent !important;
}

.MuiListItem-root.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.date-popper {
  background: $field-color;
  border: 1px solid $border-field-color;
  border-radius: 4px;
  z-index: 50;
}

.date-popper-expert {
  margin-top: 5px;
}

.date-popper .MuiPickersStaticWrapper-root {
  background: $field-color !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.date-popper .MuiPickersArrowSwitcher-iconButton {
  background: $field-color !important;
}

.date-popper .MuiPickersDesktopDateRangeCalendar-calendar {
  min-height: 260px !important;
}

.date-popper .Mui-selected {
  color: white !important;
  background-color: $link-color !important;
}

.date-popper .MuiPickersDay-root {
  border-radius: 6px !important;
}

.date-popper .MuiPickersDateRangeDay-rangeIntervalDayHighlightStart.MuiPickersDateRangeDay-rangeIntervalDayHighlight {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.date-popper .MuiPickersDateRangeDay-rangeIntervalDayHighlightEnd.MuiPickersDateRangeDay-rangeIntervalDayHighlight {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.date-popper .MuiPickersDateRangeDay-rangeIntervalDayHighlight {
  background-color: $range-color !important;
}

.date-popper .MuiPickersDateRangeDay-rangeIntervalDayHighlight:last-child {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.date-popper .MuiPickersDateRangeDay-rangeIntervalDayHighlight:first-child {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.date-popper .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer:not(:last-child) {
  border-right: 1px solid $inactive-color;
}

.date-range-label {
  margin-top: 25px !important;
  font-weight: normal !important;
  font-size: 18px !important;
}

.date-range-date {
  margin-top: 15px !important;
  font-weight: normal !important;
  font-size: 14px !important;
}

.date-range-date .MuiInput-underline:before {
  border-bottom: 1px solid $inactive-color !important;
}

.date-range-text-field-box {
  margin: 5px;
}

.date-range-text-field .MuiInput-root{
  background: $field-color url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1.5' y='1.5' width='13' height='14' rx='1.5' stroke='%23999999'/%3E%3Crect x='4' y='11' width='2' height='2' rx='1' fill='%23999999'/%3E%3Crect x='7' y='11' width='2' height='2' rx='1' fill='%23999999'/%3E%3Crect x='10' y='11' width='2' height='2' rx='1' fill='%23999999'/%3E%3Crect x='4' y='7' width='2' height='2' rx='1' fill='%23999999'/%3E%3Crect x='7' y='7' width='2' height='2' rx='1' fill='%23999999'/%3E%3Crect x='10' y='7' width='2' height='2' rx='1' fill='%23999999'/%3E%3Crect x='2' y='4' width='12' height='1' fill='%23999999'/%3E%3Crect x='4' width='1' height='3' rx='0.5' fill='%23999999'/%3E%3Crect x='11' width='1' height='3' rx='0.5' fill='%23999999'/%3E%3C/svg%3E") no-repeat 94% center;
  border: 1px solid $border-field-color;
  border-radius: 4px;
  width: 150px;
}

.date-range-text-field.ep-failed-validate-field .MuiInput-root {
  border: 1px solid #f50057;
}

.date-range-text-field .MuiInput-underline:before{
  border: none !important;
}

.date-range-text-field .MuiInput-underline:after{
  border: none !important;
}

.date-range-text-field .MuiInput-underline:focus{
  border: none !important;
}

.statistic-title {
  margin-bottom: 24px !important;
}

.box-select-statistics {
  margin-left: -5px !important;
  margin-bottom: 10px !important;
}

.table-margin-statistics {
  margin-left: -14px !important;
  margin-right: -14px !important;
}
.ep-failed-validate-field .MuiInputBase-root,
.ep-failed-validate-field fieldset,
.ep-failed-validate-field {
  border-color: $error-field-color !important;
  border-radius: 4px;
}

.desk-description.expert-page {
  margin: 32px 0 10px 24px;
}

.desk-description.expert-page p {
  font-size: 18px;
  color: $text-light-title-color;
}

.desk-description.expert-page p b {
  color: $text-color;
  font-weight: normal;
}
.select-desk.desk-button {
  min-width: 140px;
  height: 64px;
  margin: 10px;
}

.client-handling.ticket-title {
  font-weight: 500;
  font-size: 24px;
  color: $text-light-title-color;
}

.client-handling.ticket-title b {
  color: $text-color;
}

.ep-client-data.cl-field {
  margin: 5px;
  min-width: 270px;
}

.ep-client-data.cl-field.area-field {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 10px;
  min-width: 296px !important;
}

.ep-client-data.cl-field.area-field .MuiInput-root {
  height: 130px !important;
}

.phone-active-label .MuiInputLabel-formControl {
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
}

.ep-expert-data.ed-field {
  margin: 5px;
  width: 270px;
}

.ep-expert-data.ed-field .MuiInput-root {
  background-color: $field-color;
  border: 1px solid $border-field-color;
  border-radius: 4px;
}

.ep-expert-data.ed-field .MuiInput-underline:before {
  border: none !important;
}

.ep-expert-data.ed-field .MuiInput-underline:after {
  border: none !important;
}

.ep-expert-data.ed-field .MuiInput-underline:focus {
  border: none !important;
}

.info-session-dialog {
  max-width: none !important;
}

.info-session-dialog
  .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  max-width: none !important;
}

.before-connector.timeline-connector {
  flex-grow: 0;
  height: 7px;
}

.history-visit-date {
  text-decoration: underline;
  cursor: pointer;
}

.info-session-text-title {
  margin-top: 20px !important;
  font-weight: normal !important;
  font-size: 13px !important;
  color: $text-light-title-color;
}

.info-session-text {
  font-weight: normal !important;
  font-size: 14px !important;
}

.expert-select {
  margin: 0 5px 0 !important;
}

.expert-select .st-select-field.st-cities-field {
  margin: 16px 0 0 !important;
}

.select-popover .MuiPaper-elevation8 {
  background-color: white !important;
}

.comment-text-short {
  cursor: pointer;
}

.total_time {
  font-size: 14px;
}

.comments-dialog {
  .comments-dialog-text-title {
    font-size: 13px;
    color: $text-light-title-color;
  }

  .comments-dialog-text-message {
    font-size: 14px;
    color: $text-color;
  }
}

.comments-dialog
  .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  max-width: 500px !important;
}

.comments-dialog-content-first-item {
  margin-bottom: 20px;
}

.comments-dialog-content {
  margin-bottom: 20px;
  padding-top: 20px;
  border-top: 1px $border-field-color solid;
}

.select-ticket-button {
  @extend .detail-state;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 0;
  margin-right: 14px;

  &:hover {
    background: $active-tab-color;
    color: white;
  }
}

.forward-dialog {
  min-width: 350px !important;
}

.forward-dialog
  .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  min-width: 350px !important;
}


.client-panel-comment {
    background-color: $field-color;
    border: 1px solid $border-field-color;
    border-radius: 4px;
    min-height: 130px;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 18px;
    max-width: 275px;
    word-wrap:break-word;
}

.detail-panel-comment {
    @extend .client-panel-comment;
    width: 250px;
}

.panel-comment-title {
    color: $comment-panel-title-text !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}

.title-comments {
    color: $text-light-title-color !important;
    font-size: 13px !important
}

.ep-client-data.cl-field.area-field.edit-comment {
    min-width: 272px !important;
    margin-right: 10px;
}

.ep-client-data.cl-field.area-field.detail-panel-edit-comment {
    min-width: 260px !important;
    margin-top: -6px !important;
}

.edit-button-comment {
    margin-right: 23.5px !important;
}
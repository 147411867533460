.tv-screen-wrapper{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tv-screen-wrapper .tv-logo{
  width: 62vw;
  height: 23vh;
}

.tv-screen-wrapper .tv-logo-caption{
  font-weight: normal;
  font-size: 1rem;
  margin-top: 7vh;
}
*{
  font-family: "YS Text", serif;
}
.tv-wrapper{
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
}
.tv-header{
  display: flex;
  flex-shrink: 0;
  background-color: #FFDE40;
  width: 100%;
  height: 8.75vh;
  font-size: 3vh;
  text-transform: uppercase;
  color: #222222;
}
.tv-header div{

  margin-top: 2.9vh;
}
.ticket{
  left: 3.6vw;
  position: absolute;
}
.desk{
  left: 22vw;
  position: absolute;
}
.expert{
  left: 33.2vw;
  position: absolute;
}
.waiting{
  left: 50vw;
  position: absolute;
}
.tv-queue{
  display: flex;
  flex-grow: 1;
}
.tv-desk-queue{
  width: 50%;
  font-size: 9vw;
  align-self: stretch;
}
.tv-in-queue{
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  color: #FFFFFF;
  width: 50vw;
  background-color: #333;
  padding: 2vh 1vw 0 3vh;
}
.tv-ticket-number-queue{
  margin: 1vh 1vw;
  font-weight: 500;
  font-size: 5vh;
}
.tv-ticket-queue{
  display: flex;
  border-bottom: 1px solid #FFDE40;
  margin: 0 3vw;
  align-items: center;
}

.tv-ticket-queue.size-5 div{
  font-weight: normal;
  font-size: 3vh;
}
.tv-ticket-queue.size-5 .bolder{
  font-style: normal;
  font-weight: 500;
  font-size: 5.8vh;
}

.tv-ticket-queue.size-6 div{
  font-weight: normal;
  font-size: 4vh;
}
.tv-ticket-queue.size-6 .bolder{
  font-style: normal;
  font-weight: 500;
  font-size: 6vh;
}

.tv-ticket-queue.size-7 div{
  font-weight: normal;
  font-size: 3.8vh;
}
.tv-ticket-queue.size-7 .bolder{
  font-style: normal;
  font-weight: 500;
  font-size: 4.8vh;
}

.tv-ticket-queue.size-8 div{
  font-weight: normal;
  font-size: 2.2vh;
}
.tv-ticket-queue.size-8 .bolder{
  font-style: normal;
  font-weight: 500;
  font-size: 4vh;
}

.tv-ticket-queue.size-8 .bolder.queue{
  font-size: 3vh;
}

.tv-ticket-q-ticket{
  min-width: 12.9vw;
  margin-left: 0.3vw;
}
.tv-ticket-q-arrow{
  width: 2.6vw;
  height: 3.6vh;
  margin-left: 0;
}

.tv-dialog-arrow{
  height: 4vw;
  width: 6vw;
  margin-left: 4vw;
  margin-right: 3.5vw;
}

.tv-dialog-bolder{
  padding-top: 20px !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20vh !important;
}

.tv-dialog-bolder-window{
  @extend .tv-dialog-bolder;
  font-size: 16vh !important;
}

.tv-modal-title-text {
  color: $text-color;
  font-weight: 700 !important;
  font-size: 5vh;
  text-transform: uppercase;
  margin-left: 20px;
}

.tv-ticket-dialog .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  max-width: 1100px !important;
}

.tv-desk-queue-small.tv-desk-queue{
  width: 50%;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

.tv-desk-queue-small .tv-ticket-q-ticket{
  min-width: 6vw;
  margin-left: 0.3vw;
}

.tv-desk-queue-small .tv-ticket-queue{
  display: flex;
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
  border-bottom: 1px solid #FFDE40;
  margin: 0 1vw;
  align-items: center;
  height: 8vh;
}
//.tv-desk-queue-small .tv-ticket-queue div{
//  font-weight: normal;
//  font-size: 2vh;
//}
//.tv-desk-queue-small .tv-ticket-queue .bolder{
//  font-style: normal;
//  font-weight: 500;
//  font-size: 4vh;
//}
.tv-desk-queue-small .tv-ticket-q-desk{
  min-width: 4vw;
  margin-left: 1vw;
}
//.tv-desk-queue-small .tv-ticket-queue .tv-ticket-q-avatar{
//  width: 3vw;
//  height: 6vh;
//  margin: 1vh 0;
//}
.tv-desk-queue-small .tv-ticket-q-expert{
  margin-left: 1vw;
}

.tv-ticket-q-desk{
  min-width: 10.7vw;
  margin-left: 3.1vw;
  margin-top: 5px;
  margin-bottom: 5px;
}

.tv-ticket-queue.size-5 .tv-ticket-q-avatar{
  width: 10.5vh;
  height: 10.5vh;
  margin: 2.6vh 0;
}
.tv-ticket-queue.size-6 .tv-ticket-q-avatar{
  width: 7.5vh;
  height: 7.5vh;
  margin: 2.6vh 0;
}
.tv-ticket-queue.size-7 .tv-ticket-q-avatar{
  width: 7vh;
  height: 7vh;
  margin: 2.6vh 0;
}
.tv-ticket-queue.size-8 .tv-ticket-q-avatar{
  width: 4vh;
  height: 6vh;
  margin: 2.6vh 0;
}

.tv-ticket-q-expert{
  margin-left: 2.5vw;
}

.tv-wrapper{
  background-color: white;
}

.tv-header-small{
  display: flex;
  background-color: #FFDE40;
  width: 100%;
  height: 6vh;
  font-size: 2.5vh;
  text-transform: uppercase;
  color: #222222;
  min-height: 6vh;
}
.tv-header-small  div{
  margin-top: 1.4vh;
}
.tv-header-small .ticket1{
  left: 1.3vw;
  position: absolute;
}
.tv-header-small .desk1{
  left: 10.5vw;
  position: absolute;
}
.tv-header-small .expert1{
  left: 18.5vw;
  position: absolute;
}

.tv-header-small .ticket2{
  left: 24vw;
  position: absolute;
}
.tv-header-small .desk2{
  left: 34vw;
  position: absolute;
}
.tv-header-small .expert2{
  left: 50.6vw;
  position: absolute;
}
.tv-header-small .waiting{
  left: 50vw;
  position: absolute;
}

.truncate {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-small {
  @extend .truncate;
  max-width: 11vw;
}

//@media(min-width: 1800px) {
//
//  .tv-ticket-queue.size-5 div{
//    font-weight: normal;
//    font-size: 50px;
//  }
//  .tv-ticket-queue.size-5 .bolder{
//    font-style: normal;
//    font-weight: 500;
//    font-size: 80px;
//  }
//
//  .tv-ticket-queue.size-6 div{
//    font-weight: normal;
//    font-size: 50px;
//  }
//  .tv-ticket-queue.size-6 .bolder{
//    font-style: normal;
//    font-weight: 500;
//    font-size: 80px;
//  }
//
//  .tv-ticket-queue.size-7 div{
//    font-weight: normal;
//    font-size: 50px;
//  }
//  .tv-ticket-queue.size-7 .bolder{
//    font-style: normal;
//    font-weight: 500;
//    font-size: 80px;
//  }
//
//  .tv-ticket-queue.size-8 div{
//    font-weight: normal;
//    font-size: 36px;
//  }
//  .tv-ticket-queue.size-8 .bolder{
//    font-style: normal;
//    font-weight: 500;
//    font-size: 50px;
//  }
//
//
//  .tv-ticket-queue.size-5 .tv-ticket-q-avatar{
//    width: 118px;
//    height: 118px;
//    margin: 2.6vh 0;
//  }
//  .tv-ticket-queue.size-6 .tv-ticket-q-avatar{
//    width: 118px;
//    height: 118px;
//    margin: 2.6vh 0;
//  }
//  .tv-ticket-queue.size-7 .tv-ticket-q-avatar{
//    width: 110px;
//    height: 110px;
//    margin: 2.6vh 0;
//  }
//  .tv-ticket-queue.size-8 .tv-ticket-q-avatar{
//    width: 68px;
//    height: 68px;
//    margin: 2.6vh 0;
//  }
//
//}

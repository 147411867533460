.head-logo {
    background-image: url($logo-image);
    background-repeat: no-repeat;
    background-position: top;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: 260px 260px;
}

.back-button {
    position: fixed;
    top: 105px;
    left: 90px;
}

h2.phone-header {
    margin: 5px 0 10px;
    font-size: 5vh;
}

html,
body,  #root{
    height: 100%;
    overflow: hidden;
}

#root h2.tr-cl-caption {
    font-size: 5vh;
    margin-top: 40px;
    margin-bottom: 4px;
}

h2.page-title {
    margin: 5px 0 20px;
}

.time-slots {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin: 18px;
}

.time-slots button {
    margin: 12px;
    font-size: 28px;
    width: 264px;
    border-radius: 10px;
    height: 68px;
}

.time-slot-data-picker .MuiPickersCalendarHeader-daysHeader span {
    font-size: 28px;
    width: 100px;
    height: 60px;
    color: #000;
}

.time-slot-data-picker .MuiPickersCalendarHeader-switchHeader .MuiPickersCalendarHeader-transitionContainer {
    height: 70px;
    margin-top: 50px;
    margin-bottom: 40px;
    padding-bottom: 13px;
    border-bottom: 2px solid #D0D0D0;
}

.time-slot-data-picker .MuiPickersCalendarHeader-switchHeader p {
    font-size: 30px;
}

.time-slot-data-picker {
    height: 700px;
    background-color: #f5f5f5;
    width: 836px;
    border-radius: 10px;
}

.time-slot-data-picker button.MuiPickersDay-day {
    font-size: 28px;
    width: 80px;
    height: 80px;
    margin: 0 12px;
    border-radius: 10px;
}

.time-slot-data-picker .MuiPickersDay-day * {
    font-size: 28px;
}

.terminal-page {
    font-size: 28px;
}

h1.ticket-number {
    margin: 80px 0 60px;
    font-size: 165px;
}
#root .tr-cl-caption{
    margin: 100px 0 10px ;
}

#root .tr-cl-field input{
    font-size: 4vh;
}

#root .tr-cl-field .Mui-focused:after{
    border-bottom: 2px solid rgb(255, 222, 64);
}

.screen-key-board-box{
    width: 70%;
    margin-bottom: 10px;
}

.screen-key-board-box input[name=keyboardinp]{
    /* visibility: hidden; */
    width: 1px;
    height: 1px;
    position: absolute;
    bottom: -2px;
}

.screen-key-board-box .keyboard.keyboard-wrapper{
    position: static;
}

.hg-theme-default .hg-button {
    height: 7vh !important;
}

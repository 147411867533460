.detail-state {
    border: 1px solid $active-tab-color;
    border-radius: 16px;
    padding-right: 19px;
    padding-left: 19px;
    padding-bottom: 1px;
    margin-left: 20px;
    font-size: 13px;
}

.detail-state .title-state {
    color: $text-light-title-color;
    margin-right: 5px;
}

.detail-state .text-state {
    color: $text-color;
    font-weight: 500;
}

.title-data-session {
    font-weight: 400 !important;
    font-size: 18px !important;
}

.title-history-change {
    color: $link-color !important;
    font-size: 14px !important;
    cursor: pointer !important;
}

.print-block {
    cursor: pointer;
    margin-left: 24px;
    margin-right: 24px;

    .text {
        margin-left: 9px;
        font-size: 12px;
    }
}

.print-ticket-content {

    .text1 {
        font-weight: 500;
        font-size: 28px;
        text-align: center;
    }

    .text-additional {
        font-weight: 500;
        margin-top: 14px;
        font-size: 18px;
        text-align: center;
    }

    .text2 {
        font-weight: 500;
        font-size: 24px;
        text-align: center;
    }

    .text3 {
        font-weight: 500;
        font-size: 96px;
        text-align: center;
    }

}
.success-snackbar {
    background: white !important;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.11);
    border-radius: 3px;
    padding: 14px 10px;
}

.success-snackbar .text-snackbar {
    font-size: 14px;
    color: $text-light-title-color;
}

.clear-filter-text {
    color: $link-color;
    font-size: 14px;
    font-weight: 410;
    cursor: pointer;
    margin-left: 24px;
    margin-bottom: 20px;
}

.title-page {
    font-size: 24px !important;
    font-weight: 600 !important;
}

.title-page-margin {
    margin: 28px 0 14px 24px
}
.MuiTimelineItem-missingOppositeContent:before{
  display: none;
}

.MuiTimelineContent-root.MuiTimelineItem-content{
  padding-top: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: $text-color;
}

ul.MuiTimeline-root{
  padding-left: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

.MuiTimelineItem-root.MuiTimelineItem-alignLeft.MuiTimelineItem-missingOppositeContent{
  min-height: 35px;
}

.before-connector.timeline-connector{
  flex-grow: 0;
  height: 7px;
}

.MuiTimelineSeparator-root > .MuiTimelineDot-root{
  margin-top: 0;
  margin-bottom: 0;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink.ep-select-lable{
  transform: translate(19px, -1px) scale(0.75);
}
.MuiFormLabel-root.ep-select-lable{
  transform: translate(15px, 25px) scale(1);
}
.ep-expert-data.ed-field.st-data-picker .MuiInputBase-root{
  margin: 0 5px;
}

.st-data-picker.ep-expert-data.ep-field input{
  height: 35px;
}

.st-data-picker.ep-expert-data.ep-field{
  margin-top: 0;
}

.st-data-picker.ep-expert-data.ep-field label{
  transform: translate(15px, 0px);
  z-index: 1;
  background-color: $field-color;
  padding: 0 3px;
  font-size: 13px;
}

.st-data-picker.ep-expert-data.ep-field .MuiInputBase-root{
  margin-top: 5px;
  padding-left: 12px;
  width: 196px;
}

.date-label-expert {
  font-size: 12px !important;
  height: 16px;
  color: $text-light-title-color !important;
  padding-bottom: 1px;
}

.date-single-poper.date-popper .MuiPickersDay-root {
  background-color: $field-color;
}

.select-search .st-select-field.st-select-form {
  margin: 0;
  margin-top: 2px;
  width: auto;
}

.select-search.st-select-field.st-cities-field {
  margin: 0;
  margin-right: 15px;
  border: none;
}


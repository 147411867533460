.primary-button {
    background-color: $active-tab-color !important;
    color: $text-color !important;
    border-color: transparent !important;
    box-shadow: none !important;

    &:hover {
        background-color: $primary-button-hover !important;
    }
    &:active {
        background-color: $primary-button-hover !important;
    }
    &:disabled {
        background-color: $inactive-color !important;
    }
}

.alarm-button {
    border-color: $error-field-color !important;
    color: $text-color !important;
    background-color: white !important;

    &:hover {
        background-color: $error-field-color !important;
        color: white !important;
    }
    &:active {
        background-color: $error-field-color !important;
        color: white !important;
    }
    &:disabled {
        border-color: $inactive-color !important;
        color: $inactive-color !important;
    }
}

.default-button {
    border-color: $text-light-title-color !important;
    color: $text-color !important;
    background-color: white !important;

    &:hover {
        border-color: $primary-button-hover !important;
        color: $text-color !important;
        background-color: white !important;
    }
    &:active {
        border-color: $primary-button-hover !important;
        color: $text-color !important;
        background-color: white !important;
    }
    &:disabled {
        border-color: $inactive-color !important;
        color: $inactive-color !important;
    }
}

.default-button-terminal {
    width: 840px;
    height: 120px;
    font-size: 42px !important;
    border-width: 3px !important;
}

.active-default-button-terminal {
    border-color: $primary-button-hover !important;
}

.select-button {
    background-color: $select-button-background !important;
    color: $text-color !important;
}
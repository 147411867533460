.dashbord-wrp .dash-plate {
  margin: 30px 18px 30px 0;
  min-height: 152px;
  flex-grow: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dash-plate-time {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  max-height: 152px;
}

.dashbord-wrp .dash-plate:last-child {
  margin-right: 0;
}

.dash-plates {
  margin-left: -5px;
}

.dashbord-wrp .dash-plates .st-select-field:first-child{
  margin-left: 0;
}

.dashbord-wrp .dash-plate-caption {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: $text-light-caption-color;
  margin: 0;
}

.dashbord-wrp .dash-plate-time{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: $text-color;
  margin: 0;
}

.dashbord-wrp .dash-plate .dash-plate-value{
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  color: $dashboard-result-color;
  margin: 9px;
}

.dashbord-wrp .dash-plate .dash-plate-time-value{
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: $text-color;
  margin: 9px;
}

.dashbord-wrp .dash-plate .dash-plate-value span{
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: $text-color;
  margin: 9px;
}

.dashbord-wrp .MuiCardHeader-root{
  background-color: $card-header-color;
  border-bottom: 2px solid $border-card-header-color;
}

.subtitle-dashboard {
  font-size: 20px;
  font-weight: 500;
}

.plate-time-dash {
  border: none !important;
  text-align: center;
  font-size: 24px !important;
  padding-bottom: 12px;
  margin-right: 0 !important;
}

.divider-time-dash {
  width: 10px;
}
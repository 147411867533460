.modal-additional-text {
    color: $text-light-title-color !important;
}

.modal-primary-button {
    @extend .primary-button;
    height: 40px;
    width: 156px;
    cursor: pointer;
    border-radius: 4px;
}

.modal-title {
    font-size: 20px !important;
    font-weight: 600 !important;
}
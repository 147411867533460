@import "variables";

body {
  margin: 0;
  font-family: -apple-system, 'YS Text', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTableBody-root .MuiSwitch-root {
  margin: -10px 0;
}

.info-window {
  background: $background-color !important;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@import "button";
@import "mini_drawer";
@import "menu";
@import "dashboard";
@import "tv";
@import "terminal";
@import "statistics";
@import "new_ticket";
@import "mini_drawer";
@import "table";
@import "time_slot";
@import "history";
@import "modal";
@import "detail";
@import "expert";
@import "common";
@import "new_ticket";
@import "comment";
